import { createRouter, createWebHistory } from "vue-router";
import { StorageSerializers, useStorage } from "@vueuse/core"

import { mainStore } from "@/stores/mainStore";

import { sbUserStore } from "@salesbooster/vue/store/userStore";

import routes from "./routes"

let elem_id = "#salesbooster" // need default value for tests (but we should fix this so we don't need the querySelector)
if (document.querySelector(".init_vue_app")) {
  elem_id = "#" + document.querySelector(".init_vue_app").id
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});

const enricher_url = "/app/enricher";

router.beforeEach(async (to, from, next) => {
  const main_store = mainStore();
  const sb_user_store = sbUserStore();

  if (elem_id === "#salesbooster") {
    if (!sb_user_store.user.is_authenticated) {
      await sb_user_store.fetchUser();
    }
  } else if (!main_store.user) {
    if (!main_store.user_api_url) {
      if (to.path.includes(enricher_url)) {
        await main_store.setUserApiUrl(main_store.urls_names["userweb:enricher:enricher-user"]);
      } else {
        await main_store.setUserApiUrl(main_store.urls_names["dp-api-user:me"]);
      }
    }
    await main_store.updateUserData();
  }

  // create title as meta.title | Leadspicker
  // if not defined use current title, this is useful for nested routes, such as emails -> detail -> signature
  if (to.meta?.title) {
    const ending = elem_id === "#data_enricher" ? " | Pipebooster" : " | Leadspicker";
    document.title = `${to.meta.title}${ending}`;
  }

  // check if user has the role to access the route
  if (to.meta.roles) {
    if (!to.meta.roles.includes(sb_user_store.user.organization?.group)) {
      next({ name: "salesbooster_list" });
      return;
    }
  }

  if (to.meta.has_invitations) {
    if (!sb_user_store.user.organization_invitations.length) {
      next({ name: "salesbooster_list" });
      return;
    }
  }

  if (to.meta.billing) {
    if (!sb_user_store.can_see_billing) {
      if (sb_user_store.is_member) {
        next({ name: "salesbooster_user_team_management_info" });
      } else {
        next({ name: "salesbooster_list" });
      }
      return;
    }
  }

  if (to.matched.some((record) => record.meta.guest)) {
    next();
    return;
  }

  if (
    main_store.user?.is_authenticated ||
    (elem_id === "#salesbooster" && sb_user_store.user.is_authenticated)
  ) {
    next();
    return;
  }

  if (to.path.includes(enricher_url)) {
    next({ name: "enricher_login" });

  } else if (elem_id === "#salesbooster") {
    const redirectRouteStorage = useStorage("redirect-route", null, undefined, { serializer: StorageSerializers.object });
    redirectRouteStorage.value = { name: to.name, params: to.params, query: to.query };
    console.log("router", redirectRouteStorage.value);
    next({ name: "salesbooster_login", query: { redirect: "true" } });

  } else {
    window.location.href="/login"
  }
});

export default router;
