import { useRouter } from "vue-router";

import { useConfirmationModal } from "@common/vue/store/confirmationModalStore";

export function useUpgradeModal() {
  const router = useRouter();

  return function showUpgradeModal() {
    useConfirmationModal({
      title: "Upgrade your plan",
      html_body: `
        <div class="body-2">
          <p>You are currently on a free plan.</p>
          <p>In order to proceed, upgrade your plan.</p>
        </div>
      `,
      confirm_button_text: "Upgrade plan",
      confirm_button_icon: "bolt",
      cancel_button_text: "Cancel",
    }).then(() => {
      router.push({ name: "salesbooster_user_subscription" });
    });
  };
}