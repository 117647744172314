/**
 * @type {import('vanilla-cookieconsent').CookieConsentConfig}
 */
const config = {
  guiOptions: {
    consentModal: {
      layout: "box",
      position: "bottom left",
      equalWeightButtons: true,
      flipButtons: false
    },
    preferencesModal: {
      layout: "box",
      position: "right",
      equalWeightButtons: true,
      flipButtons: false
    }
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true
    },
    analytics: {
      enabled: true,
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,  // google analytics cookies
          },
          {
            name: /^(SL_C_|SL_L_)/,  // smartlook cookies
          }
        ],
      },
    },
  },
  language: {
    default: "en",
    autoDetect: "browser",
    translations: {
      en: {
        consentModal: {
          title: "Welcome to Leadspicker!",
          description: "We use cookies to enhance your experience on our site. By continuing, you agree to our use of cookies. For more details, please review our Privacy Policy and Terms of Service.",
          acceptAllBtn: "Accept All",
          showPreferencesBtn: "Manage Preferences",
          footer: `<a href="https://leadspicker.com/legal-pages/privacy-policy/">Privacy Policy</a>
                 <a href="https://leadspicker.com/legal-pages/terms-of-service/">Terms of Service</a>`
        },
        preferencesModal: {
          title: "Consent Preferences Center",
          acceptAllBtn: "Accept all",
          acceptNecessaryBtn: "Reject all",
          savePreferencesBtn: "Save preferences",
          closeIconLabel: "Close modal",
          serviceCounterLabel: "Service|Services",
          sections: [
            {
              "title": "Cookie Usage",
              "description": "We use cookies to enhance your experience and analyze usage of our platform through tools such as Google Analytics, Smartlook."
            },
            {
              "title": "Strictly Necessary Cookies <span class=\"pm__badge\">Always Enabled</span>",
              "description": "These cookies are essential for the website to function and cannot be switched off in our systems. They include cookies for core functionality such as security, network management, and accessibility.",
              "linkedCategory": "necessary"
            },
            {
              "title": "Performance and Analytics Cookies",
              "description": "These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously. This includes tools like Google Analytics and Smartlook.",
              "linkedCategory": "analytics",
              "cookieTable": {
                "headers": {
                  "name": "Cookie",
                  "domain": "Domain",
                  "desc": "Description"
                },
                "body": [
                  {
                    "name": "_ga",
                    "domain": "leadspicker.com",
                    "desc": "Used by Google Analytics to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request and used to calculate visitor, session, and campaign data for analytics reports."
                  },
                  {
                    "name": "_gid",
                    "domain": "leadspicker.com",
                    "desc": "Used by Google Analytics to store and update a unique value for each page visited, helping to track user behavior."
                  },
                  {
                    "name": "SL_*",
                    "domain": "leadspicker.com",
                    "desc": "Used by Smartlook to identify users in sessions. It contains the project key, session ID, and visitor ID, which are unique identifiers assigned to new sessions and visitors."
                  }
                ]
              }
            }

          ]
        }
      }
    }
  }
}

export default config;
