const settings = {
  sample_data_url: "https://pipebooster.io/app/enricher/enricher-export/301/?page=1&tab=all&selection_enabled=false&selected_ids=[]&unselected_ids=[]&selected_all=false&export_format=%22xlsx%22",

  // google oauth
  oauth_url: {
    // TODO generate links dynamically
    "enricher": "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?response_type=code&client_id=312686760115-3dtk6kking0s7f2sg9mi2ru8cnn8eesb.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fpipebooster.io%2Fapp%2Fenricher%2Fpb-api%2Foauth_token_recipient%2F&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile%20openid%20https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&state=E2pWP6LZWXfs6VgkpCpWNeSgDFjZqx&access_type=offline&flowName=GeneralOAuthFlow",
  },
  // stripe
  stripe_test_api_key: "pk_test_GSXpTcXT7cTLSEHrVJEc5sh2",
  stripe_live_api_key: "pk_live_oge0f9hBqckYTtaw8HWykvSk",
  // enricher
  enterprise_plan_link: "https://leadspicker.pipedrive.com/scheduler/kwa6Rux/pipeboosterio-meeting",
  enricher_show_low_email_credits_communication_treshold: 100,

}

const {
  urls_names,
  is_debug ,
  sb_recaptcha_site_key,
  pb_recaptcha_site_key,
  oauth_client_ids,
  tools_request_limit,
  sb_oauths,
  sb_paid_tasks_costs,
  timezone,
  id_to_country,
} = JSON.parse(document.getElementById("vue_django_settings").textContent);

export {
  id_to_country,
  is_debug,
  oauth_client_ids,
  pb_recaptcha_site_key,
  sb_oauths,
  sb_paid_tasks_costs,
  sb_recaptcha_site_key,
  settings,
  timezone,
  tools_request_limit,
  urls_names,
}
