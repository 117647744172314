import { defineStore } from "pinia"

import axios from "../axios";
import {
  is_debug,
  oauth_client_ids,    pb_recaptcha_site_key,
  sb_oauths,
  sb_recaptcha_site_key,
  settings,
  timezone,
  tools_request_limit,
  urls_names
} from "../utils/settings.js";

export const mainStore = defineStore({
  id: "main",
  state: () => ({
    // user data
    user: null,
    user_api_url: "",
    // settings data
    is_debug: is_debug,
    settings: settings,
    urls_names: urls_names,
    sb_recaptcha_site_key: sb_recaptcha_site_key,
    pb_recaptcha_site_key: pb_recaptcha_site_key,
    oauth_client_ids: oauth_client_ids,
    sb_oauths: sb_oauths,
    tools_request_limit: tools_request_limit,
    timezone: timezone,
  }),
  getters: {},
  actions: {
    async update_csrf_token(token) {
      if (!token)
        return;
      console.log("Updating csrf token to "+token)

      // Update DOM element
      document.getElementById("csrf_token").innerHTML = "\""+token+"\""

      // Update axios default
      axios.interceptors.request.use(config => {
        config.headers["x-csrftoken"] = token
        return config;
      });
    },

    async updateUserData(user_data=null) {
      if (user_data) {
        this.user = user_data;
        return;
      }

      try {
        const resp = await axios.get(this.user_api_url);
        this.user = resp.data;
      } catch {
        this.user = { is_authenticated: false }
      }
    },

    async setUserApiUrl(user_api_url) {
      this.user_api_url = user_api_url;
    },
  }
});
