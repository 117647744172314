const routes = [
  {
    path: "",
    name: "enricher_list",
    component: () => import("@enricher/vue/pages/EnricherList.vue"),
    meta: { title: "Projects" },
  },
  {
    path: "project/:id",
    name: "enricher_detail",
    component: () => import("@enricher/vue/pages/EnricherDetail.vue"),
    meta: { title: "Project Detail" },
  },
  {
    path: ":id/settings",
    name: "enricher_project_settings",
    component: () => import("@enricher/vue/pages/EnricherProjectSettings.vue"),
    meta: { title: "Project Settings" },
  },
  {
    path: "import",
    name: "enricher_import_options",
    component: () => import("@enricher/vue/pages/EnricherImportOptions.vue"),
    meta: { title: "Import" },
  },
  {
    path: "import/spreadsheet",
    name: "enricher_spreadsheet",
    component: () => import("@enricher/vue/pages/EnricherSpreadSheet.vue"),
  },
  {
    path: "import/external-spreadsheet",
    name: "enricher_external_spreadsheet",
    component: () => import("@enricher/vue/pages/EnricherSpreadSheet.vue"),
  },
  {
    path: "import/crm",
    name: "enricher_crm",
    component: () => import("@enricher/vue/pages/EnricherCrm.vue"),
  },
  {
    path: "crm-import/:crm_type",
    name: "enricher_crm_import_options",
    component: () => import("@enricher/vue/pages/EnricherCrmSettings.vue"),
  },
  {
    path: "user",
    name: "enricher_user",
    component: () => import("@enricher/vue/pages/EnricherUser.vue"),
    children: [
      {
        path: "profile-settings",
        alias: "",
        name: "enricher_user_settings",
        component: () => import("@enricher/vue/pages/EnricherUserSettings.vue"),
        meta: { title: "User" },
      },
      {
        path: "integrations",
        name: "enricher_user_integrations",
        component: () => import("@enricher/vue/pages/EnricherUserIntegrations.vue"),
        meta: { title: "Integrations" },
      },
      {
        path: "billing",
        name: "enricher_user_billing",
        component: () => import("@enricher/vue/pages/EnricherUserBilling.vue"),
        meta: { title: "Billing" },
        children: [
          {
            path: "plans",  // used in apps/enricher/views.py if path is changed update the views.py file
            alias: "",
            name: "enricher_user_billing_plans",
            component: () => import("@enricher/vue/pages/EnricherUserBillingPlans.vue"),
          },
          {
            path: "info",
            name: "enricher_user_billing_info",
            component: () => import("@enricher/vue/pages/EnricherUserBillingInfo.vue"),
          },
          {
            path: "invoices",
            name: "enricher_user_billing_invoices",
            component: () => import("@enricher/vue/pages/EnricherUserBillingInvoices.vue"),
          }
        ]
      },
    ]
  },
  {
    path: "signup",
    name: "enricher_signup",
    component: () => import("@enricher/vue/pages/EnricherSignUp.vue"),
    meta: { guest: true },
  },
  {
    path: "signin",
    name: "enricher_login",
    component: () => import("@enricher/vue/pages/EnricherSignIn.vue"),
    meta: { guest: true },
  },
  {
    path: "forgot-password",
    name: "enricher_password_forgot",
    component: () => import("@enricher/vue/pages/EnricherPasswordForgot.vue"),
    meta: { guest: true },
  },
  {
    path: "password-reset/:uidb64/:token",
    name: "enricher_password_reset",
    component: () => import("@common/vue/pages/PasswordReset.vue"),
    meta: { guest: true },
  },
  {
    path: "email-confirmation/:uidb64/:token",
    name: "enricher_email_confirmation",
    component: () => import("@components/platform/ConfirmEmail.vue"),
    meta: { guest: true },
  },
  {
    path: "unsubscribe/:uidb64",
    name: "enricher_email_unsubscription",
    component: () => import("@components/platform/UnsubscribeEmail.vue"),
    meta: { guest: true },
  },
  {
    path: "onboarding",
    name: "enricher_onboarding",
    component: () => import("@enricher/vue/pages/EnricherOnboarding.vue"),
  },
  {
    path: "social_auth/:id_token",
    name: "enricher_social_authentication",
    component: () => import("@enricher/vue/pages/EnricherSocialAuth.vue"),
    meta: { guest: true },
  },
  {
    path: "project-not-found",
    name: "enricher_project_not_found",
    component: () => import("@common/vue/pages/ProjectNotFound.vue"),
    props: { project_list_route_name: "enricher_list" }
  },
  // route for presenting ui kit
  {
    path: "storybook",
    name: "enricher_storybook",
    component: () => import("@enricher/vue/pages/EnricherStoryBook.vue"),
  }
]
export default routes
