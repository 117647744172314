import axios from "axios";

import { getCookie } from "../utils/cookie.js"

axios.interceptors.request.use(config => {
  config.headers["x-csrftoken"] = getCookie("csrftoken") || JSON.parse(document.getElementById("csrf_token").textContent);
  return config;
});

export default axios;
