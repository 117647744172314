import $ from "jquery";
import moment from "moment";
import PrimeVue from "primevue/config";
import { markRaw } from "vue"
import { createApp } from "vue/dist/vue.esm-bundler";
import { autoAnimatePlugin } from "@formkit/auto-animate/vue"
import Aura from "@primeuix/themes/aura"

import { pinia } from "@/stores/store"

import UiKit from "./plugins/UiKit.js";
import { logError } from "./utils/log_errors";
import App from "./App.vue"
import axios from "./axios";
import router from "./router";

window.moment = moment;
window.$ = window.jQuery = $;

import { createGtm } from "@gtm-support/vue-gtm";

import CookieConsentVue from "@salesbooster/vue/plugins/CookieConsentVue";

import "@mir4a/trix";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "@mir4a/trix/dist/trix.css";


document.addEventListener("trix-before-initialize", (event) => {
  console.log("trix-before-initialize", event);
});

const SALESBOOSTER_APP_ID = "salesbooster";
const DEBUG_TRACKING = false;   // on localhost we don't want to track anything, unless we are debugging

// init pinia plugins
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

const app = createApp(App)
const app_id = document.querySelector(".init_vue_app").id
const elem_id = "#" + app_id;

app.use(pinia);
app.use(CookieConsentVue);
app.use(router);
app.use(UiKit);
app.use(autoAnimatePlugin);
app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      darkModeSelector: false,
    },
  },
  zIndex: {
    modal: 1100,   // For PrimeVue modals
    overlay: 2000, // For overlay components like Dropdown
  },
});

// do not track localhost unless we are debugging
if (window.location.hostname !== "localhost" || DEBUG_TRACKING) {
  const gtm_id = app_id === SALESBOOSTER_APP_ID ? "GTM-TCX4LNGJ" : "GTM-KPR2RWTM";  // salesbooster or pipebooster
  console.log("Using GTM ID: " + gtm_id);
  app.use(createGtm({
    id: gtm_id,
    enabled: false,   // by default it's disabled, enable it in SaleboosterApp.vue if user consented
  }));
}

app.config.errorHandler = (err, instance, info) => { logError(err, instance, info)};
app.provide("axios", axios)
app.mount(elem_id);

// import "primevue/resources/themes/saga-blue/theme.css"; //theme
// import "primevue/resources/primevue.min.css";
