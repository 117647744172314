const routes = [
  {
    path: "",
    name: "salesbooster_list",
    component: () => import("@salesbooster/vue/pages/ProjectList.vue"),
    meta: { title: "Projects" },
  },
  {
    path: "onboarding",
    name: "salesbooster_onboarding",
    component: () => import("@salesbooster/vue/pages/KycForm.vue"),
    meta: { title: "Onboarding", hideSideBar: true },
  },
  {
    path: "new-project",
    name: "salesbooster_add_new_project",
    component: () => import("@salesbooster/vue/pages/ProjectAddNew.vue"),
    meta: { title: "New Project" },
  },
  {
    path: "playbook",
    name: "salesbooster_playbook",
    component: () => import("@salesbooster/vue/pages/WorkflowsStore.vue"),
    meta: { title: "Playbook" },
  },
  {
    path: "story-book",
    name: "salesbooster_story_book",
    component: () => import("@salesbooster/vue/pages/SalesboosterStoryBook.vue"),
    meta: { title: "Story book" },
  },
  {
    path: "master-inbox",
    name: "salesbooster_master_inbox",
    component: () => import("@salesbooster/vue/pages/MasterInbox.vue"),
    meta: { title: "Master Inbox" },
  },
  {
    path: "notifications",
    name: "salesbooster_notifications_center",
    component: () => import("@salesbooster/vue/pages/NotificationCenter.vue"),
    meta: { title: "Notifications" },
  },
  {
    path: "notifications/unsubscribed",
    name: "salesbooster_notifications_unsubscribe",
    meta: { guest: true },
    component: () => import("@salesbooster/vue/pages/SalesboosterUnsubscribe.vue"),
  },
  {
    path: "project/:id",
    name: "salesbooster_project_detail",
    component: () => import("@salesbooster/vue/pages/ProjectDetail.vue"),
    children: [
      {
        path: "robots",
        name: "salesbooster_project_robots",
        component: () => import("@salesbooster/vue/pages/ProjectRobots.vue"),
        meta: { title: "Robots" },
      },
      {
        path: "robots/:robot_id",
        name: "salesbooster_project_robots_detail",
        component: () => import("@salesbooster/vue/pages/ProjectRobotsDetail.vue"),
        meta: { title: "Robot" },
      },
      {
        path: "robots/post-tracker",
        name: "salesbooster_li_robot",
        component: () => import("@salesbooster/vue/pages/RobotLinkedin.vue"),
        meta: { title: "Robots - Post tracker" },
        children: [
          {
            path: "posts",
            name: "salesbooster_li_robot_posts",
            component: () => import("@salesbooster/vue/pages/RobotLinkedinPosts.vue"),
            meta: { title: "Robots - Post tracker - Posts" },
          },
          {
            path: "accounts",
            name: "salesbooster_li_robot_accounts",
            component: () => import("@salesbooster/vue/pages/RobotLinkedinAccounts.vue"),
            meta: { title: "Robots - Post tracker - Accounts" },
          },
          {
            path: "search",
            name: "salesbooster_li_robot_search",
            component: () => import("@salesbooster/vue/pages/RobotLinkedinSearch.vue"),
            meta: { title: "Robots - Post tracker - Search" },
          }
        ]
      },
      {
        path: "robots/linkedin-people",
        name: "salesbooster_li_people_robot",
        component: () => import("@salesbooster/vue/pages/RobotLinkedinPeople.vue"),
        meta: { title: "Robots - LinkedIn People" },
      },
      {
        path: "robots/job-tracking",
        name: "salesbooster_job_tracking_robot",
        component: () => import("@salesbooster/vue/pages/RobotJobTracking.vue"),
        meta: { title: "Robots - LinkedIn People" },
      },
      {
        path: "robots/cph",
        name: "salesbooster_cph_robot",
        component: () => import("@salesbooster/vue/pages/RobotCPH.vue"),
        meta: { title: "Robots - People from Companies" },
      },
      {
        path: "robots/job-posts",
        name: "salesbooster_jobs_robot",
        component: () => import("@salesbooster/vue/pages/RobotJobPosts.vue"),
        meta: { title: "Robots - Job portals" },
      },
      {
        path: "robots/lead-hunter",
        name: "salesbooster_lead_hunter",
        component: () => import("@salesbooster/vue/pages/RobotLeadHunter.vue"),
        meta: { title: "Robots - Lead Hunter" },
      },
      {
        path: "data",
        alias: "",
        name: "salesbooster_project_data",
        component: () => import("@salesbooster/vue/pages/ProjectData.vue"),
        meta: { title: "Contacts", table_view: true },
      },
      {
        path: "sequence",
        name: "salesbooster_project_sequence",
        component: () => import("@salesbooster/vue/pages/ProjectSequence.vue"),
        meta: { title: "Sequence" },
      },
      {
        path: "report",
        name: "salesbooster_project_report",
        component: () => import("@salesbooster/vue/pages/ProjectReport.vue"),
        meta: { title: "Report" },
      },
      {
        path: "blacklist",
        name: "salesbooster_project_blacklist",
        component: () => import("@salesbooster/vue/pages/ProjectBlacklist.vue"),
        meta: { title: "Blacklists" },
      },
      {
        path: "blacklist/unsubscribed",
        name: "salesbooster_unsubscribed_text",
        props: { isUnsubscribe: true },
        component: () => import("@salesbooster/vue/pages/ProjectBlacklistImportTextInput.vue"),
        meta: { hideNavbar: true },
      },
      {
        path: "blacklist/text",
        name: "salesbooster_blacklist_text",
        component: () => import("@salesbooster/vue/pages/ProjectBlacklistImportTextInput.vue"),
        meta: { hideNavbar: true },
      },
      {
        path: "blacklist/project",
        name: "salesbooster_blacklist_project",
        component: () => import("@salesbooster/vue/pages/ProjectBlacklistImportProject.vue"),
        meta: { hideNavbar: true },
      },
      {
        path: "blacklist/spreadsheet",
        name: "salesbooster_blacklist_spreadsheet_import",
        props: { post_to: "blacklist_import_spreadsheet", on_success_navigate_to: "salesbooster_project_blacklist" },
        component: () => import("@salesbooster/vue/pages/ProjectImportSpreadSheet.vue"),
        meta: { hideNavbar: true }
      },
      {
        path: "settings",
        name: "salesbooster_project_settings",
        component: () => import("@salesbooster/vue/pages/ProjectSettings.vue"),
        meta: { title: "Project Settings" },
      },
      {
        path: "import-contacts",
        name: "salesbooster_spreadsheet_import",
        props: { post_to: "persons_import_spreadsheet", on_success_navigate_to: "salesbooster_project_data" },
        component: () => import("@salesbooster/vue/pages/ProjectImportSpreadSheet.vue"),
        meta: { hideNavbar: true }
      }
    ]
  },
  {
    path: "user",
    name: "salesbooster_user",
    component: () => import("@salesbooster/vue/pages/UserDetail.vue"),
    children: [
      {
        path: "profile-settings",
        alias: "",
        name: "salesbooster_user_settings",
        component: () => import("@salesbooster/vue/pages/UserSettings.vue"),
        meta: { title: "Profile Settings" },
      },
      {
        path: "outreach-accounts",
        name: "salesbooster_user_outreach_account",
        component: () => import("@salesbooster/vue/pages/UserOutreachAccount.vue"),
        meta: { title: "Outreach Accounts" },
      },
      {
        path: "email-account/:email_account_id",
        name: "salesbooster_user_email_account_detail",
        component: () => import("@salesbooster/vue/pages/UserEmailAccountDetail.vue"),
        meta: { title: "Email Account Detail" },
      },
      {
        path: "email-account/:email_account_id/email-signature",
        name: "salesbooster_user_email_account_signature",
        component: () => import("@salesbooster/vue/pages/UserEmailAccountSignature.vue"),
      },
      {
        path: "global-blacklist",
        name: "salesbooster_global_blacklist",
        component: () => import("@salesbooster/vue/pages/UserGlobalBlacklist.vue"),
        meta: { title: "Global Blacklist" },
      },
      {
        path: "integrations",
        name: "salesbooster_user_integrations",
        children: [
          {
            path: "",
            name: "salesbooster_user_integrations_webhooks",
            component: () => import("@salesbooster/vue/pages/integrations/HooksIntegrations.vue"),
            meta: { title: "Webhooks" },
          },
          {
            path: "apps",
            name: "salesbooster_user_integrations_apps",
            component: () => import("@salesbooster/vue/pages/integrations/AppsIntegrations.vue"),
            meta: { title: "Apps" },
          },
          {
            path: "keys",
            name: "salesbooster_user_integrations_keys",
            component: () => import("@salesbooster/vue/pages/integrations/KeysIntegrations.vue"),
            meta: { title: "Api-Keys" },
          }
        ]
      },
      {
        path: "subscription",
        name: "salesbooster_user_subscription",
        component: () => import("@salesbooster/vue/pages/UserSubscription.vue"),
        meta: { title: "Subscription", billing: true },
      },
      {
        path: "billing",
        name: "salesbooster_user_billing",
        component: () => import("@salesbooster/vue/pages/UserBilling.vue"),
        meta: { title: "Billing", billing: true },
      },
      {
        path: "team-management",
        name: "salesbooster_user_team_management",
        component: () => import("@salesbooster/vue/pages/UserTeamManagement.vue"),
        meta: { title: "Team Management", roles: ["admin", "owner"] },
      },
      {
        path: "team-management/info",
        name: "salesbooster_user_team_management_info",
        component: () => import("@salesbooster/vue/pages/UserTeamManagementInfo.vue"),
        meta: { title: "Team Management Info", roles: ["user"] },
      },
      {
        path: "team-management/invite",
        name: "salesbooster_user_team_invite",
        component: () => import("@salesbooster/vue/pages/UserTeamInvite.vue"),
        meta: { title: "Invite Team Member", roles: ["admin", "owner"] },
      },
      {
        path: "team-management/pending",
        name: "salesbooster_user_team_management_pending",
        component: () => import("@salesbooster/vue/pages/UserTeamManagementPending.vue"),
        meta: { title: "Pending Invitations", has_invitations: true },
      },
    ],
  },
  {
    path: "",
    name: "salesbooster_auth",
    meta: { guest: true },
    component: () => import("@salesbooster/vue/pages/AuthDetail.vue"),
    children: [
      {
        path: "signup",
        name: "salesbooster_signup",
        component: () => import("@salesbooster/vue/pages/AuthSignUp.vue"),
        meta: { title: "Sign Up" },
      },
      {
        path: "signin",
        name: "salesbooster_login",
        component: () => import("@salesbooster/vue/pages/AuthSignIn.vue"),
        meta: { title: "Sign In" },
      }
    ]
  },
  {
    path: "unsubscribe/:person_id/:hash_secret",
    name: "salesbooster_unsubscribe",
    component: () => import("@salesbooster/vue/pages/SalesboosterUnsubscribe.vue"),
    meta: { guest: true, title: "Unsubscribe" },
  },
  {
    path: "forgot-password",
    name: "salesbooster_password_forgot",
    component: () => import("@salesbooster/vue/pages/PasswordForgot.vue"),
    meta: { guest: true, title: "Forgotten Password" },
  },
  {
    path: "password-reset/:uidb64/:token",
    name: "salesbooster_password_reset",
    component: () => import("@salesbooster/vue/pages/SalesboosterPasswordReset.vue"),
    meta: { guest: true },
  },
  {
    path: "oauth",
    name: "salesbooster_oauth",
    component: () => import("@salesbooster/vue/pages/SalesboosterSocialAuth.vue"),
    meta: { guest: true },
    children: [
      {
        path: "microsoft",
        name: "salesbooster_oauth_microsoft",
        component: () => import("@salesbooster/vue/pages/SalesboosterSocialAuth.vue"),
        meta: { guest: true },
      }
    ]
  },
  {
    path: "oauth/:provider(gmail|outlook)",
    name: "salesbooster_oauth_email",
    component: () => import("@salesbooster/vue/pages/SalesboosterOauthEmail.vue"),
  },
]
export default routes

