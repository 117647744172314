import DOMPurify from "dompurify";
import swal, { SweetAlertOptions, SweetAlertResult } from "sweetalert2";

const originalSwalFire = swal.fire;

function sanitizeSwalOptions(options: SweetAlertOptions): SweetAlertOptions {
  // Create a new object to avoid mutating the input
  const sanitizedOptions = { ...options };

  // call DOMPurify.sanitize on all values that are strings
  for (const key in sanitizedOptions) {
    if (typeof sanitizedOptions[key] === "string") {
      sanitizedOptions[key] = DOMPurify.sanitize(sanitizedOptions[key] as string);
    }
  }
  return sanitizedOptions;
}

// Override the fire method with proper typing
swal.fire = function(options: SweetAlertOptions): Promise<SweetAlertResult> {
  return originalSwalFire.call(this, sanitizeSwalOptions(options));
}

export default swal;
