export default {
  install: (app, _options) => {
    // get path of all components in dir
    const components = import.meta.glob("../../apps/common/vue/components/platform/ui_kit/*.vue");

    for (const path in components) {
      // get file name and register component
      const component_name = path.split("/").pop().replace(".vue", "");
      components[path]().then((component) => {
        app.component(component_name, component.default);
      })
    }
  },
};
