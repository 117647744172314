import DOMPurify from "dompurify";
import swal from "sweetalert2";

import { useUpgradeModal } from "@salesbooster/vue/composables/useUpgradeModal";

export function useSwalAlert(error) {
  console.log(error);
  const title = error?.response?.data?.title || "Oops...";
  let message = error?.response?.data?.message || "Something went wrong!";

  // Handeling of special errors
  if (error.response?.status === 403) {
    const showUpgradeModal = useUpgradeModal();
    showUpgradeModal();
  } else {
    // Otherwise just use generic sweet alert
    const swalWithBootstrapButtons = swal.mixin({
      customClass: {
        confirmButton: "btn text-light back-primary"
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons
      .fire({
        icon: "error",
        title: DOMPurify.sanitize(title),
        html: DOMPurify.sanitize(message),
      });
  }
}
