import enricher_routes from "@enricher/vue/router/enricher_routes.js";
import salesbooster_routes from "@salesbooster/vue/router/salesbooster_routes.js";
import outreach_routes from "@outreach/vue/routes.js";


const routes = [
  {
    path: "/app/enricher",
    name: "enricher",
    component: () => import("@enricher/vue/EnricherApp.vue"),
    children: enricher_routes,
  },
  {
    path: "/app/sb",
    name: "salesbooster",
    component: () => import("@salesbooster/vue/TheSalesbooster.vue"),
    children: salesbooster_routes,
  },
  {
    path: "/app/outreach/:id/:hashed_pk",
    name: "outreach",
    component: () => import("@outreach/vue/OutreachApp.vue"),
    children: outreach_routes,
  },
  {
    path: "/monitoring/credits-spending",
    name: "api-credits-spending",
    component: () => import("@dpdash/vue/CreditsSpending.vue"),
  },
  {
    path: "/tools/google_tool",
    name: "google_tool",
    component: () => import("@tools/vue/google_tool.vue"),
  },
  // page not found route
  {
    path: "/:pathMatch(.*)*",
    name: "404_not_found",
    component: () => import("@common/vue/pages/PageNotFound.vue"),
  },
  {
    path: "",
    name: "dashboard",
    component: () => import("@dpdash/vue/CustomerSuccessDashboard.vue"),
  },
]

export default routes;
