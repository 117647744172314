import axios from "axios";
import { defineStore } from "pinia"

import router from "@/router";
import { resetStores } from "@/stores/store";
import swal from "@/utils/sanitizedSweetAlert";
import { urls_names } from "@/utils/settings";
import { mainStore } from "@store/mainStore";

import { useSwalAlert } from "@salesbooster/vue/composables/useSwalAlert";
import { EmailAccount, User } from "@salesbooster/vue/types/types";

interface State {
  user: User
  current_credits?: number;
  can_show_ooc_after_login: boolean;
}

export interface BillingInfoCustomer {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_name: string;
  street: string;
  city: string;
  country: string;
  zip: string;
  tax_id_number_type: string;
  tax_id_number: string;
}

export const sbUserStore = defineStore({
  id: "salesboosterUserStore",
  state: (): State => ({
    user: {
      id: null,
      email: null,
      first_name: null,
      last_name: null,
      is_authenticated: false,
      websocket_group: null,
      csrf_token: null,
      daily_scraped_leads: 0,
      created_magic_column_count: 0,
      recommended_daily_scraping_limit: 200,
      free_trial_days_left: null,
      has_free_trial: false,
      has_active_subscription: false,
      should_have_access_to_paid_features: false,
      subscription: {
        credits_available_now: null,
        stripe_subscription: {
          id: null,
          cancel_at: null,
          cancel_at_period_end: null,
          current_period_end: null,
          default_payment_method: null,
          days_until_next_payment: null,
          is_unpaid: null,
          days_until_trial_end: null,
          items: null,
          plan: {
            id: null,
            nickname: null,
            amount: null,
            currency: null,
            interval: null,
            features: null,
          }
        },
        add_ons: null,
        can_use_free_trial: null,
      },
      customer: {
        id: null,
        name: null,
        email: null,
        phone: null,
        address: null,
        tax_id: null,
      },
      config: {
        claimed_free_credits: null,
        n_allowed_email_accounts: 0,
        n_allowed_linkedin_accounts: 0,
        n_allowed_warmup_inboxes: 0,
        n_allowed_monthly_credits_spend: 0,
        n_allowed_robots: 0,
        n_allowed_seats: 0,
        feature_send_outreach: null,
        feature_reorder_sequences: null,
        feature_org_search_tags_filter: null,
        feature_master_inbox: null,
        feature_lead_hunter: null,
        allow_notifications: null,
        allow_sentiment_analysis: null,
        is_managed: null,
        ai_consent: null,
      },
      email_accounts: [],
      linkedin_accounts: [],
    },
    can_show_ooc_after_login: true,
  }),
  getters: {
    current_credits(state) {
      return state.user.subscription ? state.user.subscription.credits_available_now : 0;
    },

    customer(state) {
      return state.user.customer;
    },

    stripe_subscription(state) {
      return state.user.subscription.stripe_subscription;
    },

    full_name(state) {
      return state.user.first_name + " " + state.user.last_name;
    },

    is_managed_user(state) {
      return state.user?.config?.is_managed;
    },

    is_new_user(state) {
      if(!state.user){
        return null
      }
      const dateJoined = new Date(state.user.date_joined);
      const fourteenDaysAgo = new Date();
      fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

      const isNewUser = dateJoined >= fourteenDaysAgo;

      return isNewUser &&
        !(state.user.created_magic_column_count && state.user.created_robots_count);
    },

    daily_scraping_limit_reached(state) {
      return state.user.daily_scraped_leads >= state.user.recommended_daily_scraping_limit;
    },

    has_accounts_with_missing_scopes(state) {
      return state.user.email_accounts.some((account: EmailAccount) => !account.has_all_scopes);
    },

    is_admin(state) {
      return state.user.organization?.group === "admin";
    },

    is_owner(state) {
      return state.user.organization?.group === "owner";
    },

    is_member(state) {
      return state.user.organization?.group === "user";
    },

    can_see_billing(state) {
      return state.user.organization === null || this.is_owner;
    },

    can_see_team_management() {
      return this.is_admin || this.is_owner || this.is_member;
    },

    has_pending_invitations(state) {
      return state.user.organization_invitations.length > 0;
    },

    n_allowed_seats(state) {
      return state.user.config?.n_allowed_seats ?? 1;
    },
  },
  actions: {
    async setUser(user: User) {
      this.user = user;
    },

    async fetchUser() {
      try {
        const resp = await axios.get(urls_names["salesbooster:sb-api:me"]);
        this.setUser(resp.data);
      } catch (err) {
        if (err.response.status !== 401) {
          useSwalAlert(err);
        }
      }
    },

    async updateUserSettings(updated_user: object, swal_fire=true){
      try {
        const resp = await axios.patch(urls_names["salesbooster:sb-api:me"], updated_user);
        await this.setUser(resp.data);
        if (swal_fire) {
          swal.fire({
            position: "top-right",
            icon: "success",
            title: "User settings successfully updated",
            showConfirmButton: false,
            timer: 1500
          })
        }
      } catch (err) {
        useSwalAlert(err);
      }
    },

    async createOrUpdateUserCustomer(customer: BillingInfoCustomer) {
      const request_data = {
        name: customer.first_name + " " + customer.last_name,
        email: customer.email,
        phone: customer.phone,
        address: {
          line1: customer.company_name,
          line2: customer.street,
          city: customer.city,
          country: customer.country,
          postal_code: customer.zip,
        },
        tax_type: customer.tax_id_number_type,
        tax_id: customer.tax_id_number,
      }

      // create customer
      if (!this.user.customer?.id) {
        try {
          await axios.post(urls_names["salesbooster:sb-api:get_customer"], request_data);
          this.fetchUser();

          swal.fire({
            position: "top-right",
            icon: "success",
            title: "Saved",
            showConfirmButton: false,
            timer: 1500
          })
        } catch (err) {
          useSwalAlert(err);
        }
      // update customer
      } else {
        try {
          await axios.patch(urls_names["salesbooster:sb-api:get_customer"], request_data);
          this.fetchUser();

          swal.fire({
            position: "top-right",
            icon: "success",
            title: "Saved",
            showConfirmButton: false,
            timer: 1500
          })
        } catch (err) {
          useSwalAlert(err);
        }
      }
    },

    async loginUser(email: string, password: string) {
      const response = await axios.post(urls_names["salesbooster:sb-api:login"], { email: email, password: password });
      this.setUser(response.data)
    },

    async logoutUser() {
      const main_store = mainStore();

      try {
        const resp = await axios.delete(urls_names["salesbooster:sb-api:login"]);
        resetStores();
        await main_store.update_csrf_token(resp.data.csrf_token);
        await router.push({ name: "salesbooster_login" });
      } catch (err) {
        useSwalAlert(err);
      }
    },
  }
})
