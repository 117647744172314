const routes = [
  {
    path: "report",
    name: "outreach_report",
    alias: "",
    component: () => import("@outreach/vue/pages/OutreachReport.vue"),
    meta: {guest: true},
  },
  {
    path: "data",
    name: "outreach_data",
    component: () => import("@outreach/vue/pages/OutreachData.vue"),
    meta: {guest: true},
  },
]
export default routes
