import * as CookieConsent from "vanilla-cookieconsent";

import config from "./cookieconsent-config";

import "vanilla-cookieconsent/dist/cookieconsent.css";

export default {
  install: (app) => {
    CookieConsent.run(config);
    app.config.globalProperties.$CC = CookieConsent;
  },
};
