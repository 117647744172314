import { defineStore } from "pinia"


export const confirmationModalStore = defineStore({
  id: "confirmationModal",
  state: () => ({
    modal_id: "confirmation-modal",

    title: "Are you sure about this?",
    body: "This action cannot be undone.",
    html_body: "",

    confirm_button_text: "Confirm",
    confirm_button_class: "back-primary text-light",
    confirm_button_icon: "",

    cancel_button_text: "Cancel",
    cancel_button_class: "back-primary-extra-light text-dark",
    cancel_button_icon: "",
  }),
  getters: {},
  actions: {
    async showConfirmationModal(config) {
      this.title = config.title || "Are you sure about this?";
      this.body = config.body || "This action cannot be undone.";
      this.html_body = config.html_body || "";

      this.confirm_button_text = config.confirm_button_text || "Confirm";
      this.confirm_button_class = config.confirm_button_class || "back-primary text-light";
      this.confirm_button_icon = config.confirm_button_icon || "";

      // you could set the cancel_button_text to "" to not display the cancel button
      this.cancel_button_text = config.cancel_button_text === "" ? "" : (config.cancel_button_text || "Cancel");
      this.cancel_button_class = config.cancel_button_class || "back-primary-extra-light text-dark";
      this.cancel_button_icon = config.cancel_button_icon || "";

      $(`#${this.modal_id}`).modal("show");
    }
  }
})

export function useConfirmationModal(config={}) {
  const confirmation_modal_store = confirmationModalStore();
  confirmation_modal_store.showConfirmationModal(config);

  return new Promise((resolve, reject) => {
    $(`#${confirmation_modal_store.modal_id}`).on("click", "#confirm-button", () => resolve(true));
    $(`#${confirmation_modal_store.modal_id}`).on("hidden.bs.modal", () => reject(false));
  });
}