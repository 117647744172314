
import { is_debug,urls_names } from "./settings.js";

import axios from "../axios"

const logError = (err,info) => {
  console.error(err);
  if (is_debug) {console.error(info)}

  const error_file_path = err.stack.split("\n")[1].split("/");
  const error_file_data = error_file_path[error_file_path.length - 1].split(":");

  const request_data = {
    error_type: "vue",
    page_url: window.location.href,
    err_message: err.message,
    file: error_file_data[0],
    lineNo: error_file_data[1],
    columnNo: error_file_data[2].replace(/\D/g, ""), // can sometimes end with ')' so remove non digit characters
    auth_token: "ZyQZsqrGibLgLrlnfLnJZRuubpkalH",
  }
  axios.post(urls_names["api-errorreporter"], request_data);
}

export { logError };
